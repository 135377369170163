import React from 'react';
import {graphql} from 'gatsby';
import {Header, Intro, DynamicZone, SEO} from '../components/organisms';
import {NavigationContext} from '../services/navigation';
import {PageContent} from '../components/organisms/Layout';

const Service = ({data}) => {
  const {strapi: {service: {header, intro, blocks, SEO: _SEO}}} = data;
  const {setLocation} = React.useContext(NavigationContext);
  React.useEffect(() => {
    setLocation(window.location.pathname);
  }, []);
  return (
    <PageContent>
      <SEO
        title={_SEO?.title}
        description={_SEO?.description}
        meta={[
          {
            name: 'keywords',
            content: _SEO?.keywords,
          },
          {
            property: 'og:image',
            content: `${process.env.GATSBY_CLIENT_ORIGIN}${header.media?.file?.file?.publicURL}`
          }
        ]}
      />
      <Header header={header} />
      {intro && <Intro intro={intro} />}
      <DynamicZone blocks={blocks} />
    </PageContent>
  );
};

export default Service;

export const marketQuery = graphql`
  query GET_SERVICE($id: ID!) {
    strapi {
      service(id: $id) {
        SEO {
          ...SEO
        }
        name
        header {
          ...RegularHeader
        }
        intro {
          ...Intro
        }
        blocks {
          __typename
          ...Carousel
          ...ContactUs
          ...ContactPersonBlock
          ...MediaWithText
          ...MediaBlock
          ...PlainText
          ...Share
          ...Testimonial
          ...USPBlock
          ...Download
        }
      }
    }
  }
`;
